import * as React from "react";
import Layout from "../components/Layout";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";

import WheelComponent from "react-wheel-of-prizes";

const NotFoundPage = () => {
  const { meta, social } = useSiteMetadata();

  const segments = [
    "No Forfeit",
    "Pay the Fine",
    "3 Laps",
    "30 second Karaoke",
    "20 Burpees",
    "20 Pushups",
    "Wash Bibs",
    "Clean Captains Boots",
    "Double Fine",
    "Punishment Penalties",
  ];
  const segColors = [
    "#000000",
    "#E81416",
    "#FFA500",
    "#D1CB08",
    "#79C314",
    "#487DE7",
    "#4B369D",
    "#70369D",
    "#E980FC",
    "#BF74CC",
  ];
  const onFinished = (winner) => {
    console.log(winner);
  };

  return (
    <Layout darkMenu={`true`} backgroundColour={`primary`}>
      <Seo
        title={"Spin the Wheel" + meta.sitePageTitleSep + meta.siteTitle}
        slug={meta.siteUrl + "/fine/"}
        description={meta.siteDescription}
        image={meta.siteImage}
        twitterTitle={"Spin the Wheel " + meta.sitePageTitleSep}
        facebookTitle={"Spin the Wheel " + meta.sitePageTitleSep}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />
      <div className="pt-5 container-fluid bg-white">
        <div className="py-5 text-center">
          <div>
            <WheelComponent
              segments={segments}
              segColors={segColors}
              onFinished={(winner) => onFinished(winner)}
              primaryColor="black"
              contrastColor="white"
              buttonText="SPIN"
              isOnlyOnce={false}
              size={290}
              upDuration={100}
              downDuration={1000}
              fontFamily="Arial"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
